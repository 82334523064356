import React, { useEffect, useState, useReducer } from 'react'
import axios from '../../utils/axios'
import SendIcon from '../../components/Icons/SendIcon'
import LikeCommentBox from '../../components/Video/LikeCommentBox'
import CommentListing from './CommentListing'
import { classNames } from '../../utils/index'
import ShowMore from 'react-show-more'
import userAuth from '../../utils/userAuth'
import { useTranslation } from 'react-i18next'
import Loading from '../../components/Loading'
import Popup from '../../components/Popup'
import Button from '../../components/Button/index'
import StatusBadge from '../../components/Video/StatusBadge'
import parse from 'html-react-parser'

function VideoView({ uuid, t, onEditVideo, onDeleteVideo }) {
  const { isAuthenticated } = userAuth.getAuth()
  const [currentState, setCurrentState] = useState(0)
  const { i18n } = useTranslation()

  const { language } = i18n

  function reducer(state, action) {
    const updatedValues = state
    if ('video' in action) {
      updatedValues.video = action.video
    }
    if ('comment' in action) {
      updatedValues.comment = action.comment
    }
    if ('openPopup' in action) {
      updatedValues.openPopup = action.openPopup
    }
    if ('isDeleted' in action) {
      updatedValues.isDeleted = action.isDeleted
    }
    if ('disabledButton' in action) {
      updatedValues.disabledButton = action.disabledButton
    }
    if ('loading' in action) {
      updatedValues.loading = action.loading
    }
    if ('buttonLoading' in action) {
      updatedValues.buttonLoading = action.buttonLoading
    }
    if ('render' in action && !!action.render) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }

  const [values, dispatch] = useReducer(reducer, {
    video: null,
    loading: true,
    buttonLoading: false,
    openPopup: false,
    isDeleted: false,
    comment: '',
    disabledButton: true,
    render: false
  })

  const {
    loading,
    buttonLoading,
    video,
    isDeleted,
    openPopup,
    disabledButton,
    comment
  } = values

  useEffect(() => {
    let isMounted = true
    fetchData(isMounted)
    return () => {
      isMounted = false
    }
  }, [])

  async function fetchData(isMounted) {
    try {
      const resp = await axios.get(`/videos/${uuid}`)
      const { video } = resp
      dispatch({
        video: video,
        loading: false,
        render: isMounted
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function submitComment() {
    if (disabledButton) {
      return
    }
    dispatch({
      buttonLoading: true,
      render: true
    })
    try {
      let formData = new FormData()
      formData.append('comment[content]', values.comment)
      const resp = await axios.post(`/videos/${uuid}/create_comment`, formData)

      const { success } = resp
      if (success) {
        dispatch({
          buttonLoading: false,
          disabledButton: true,
          openPopup: true,
          comment: '',
          render: true
        })
      }
    } catch (error) {
      if (error && error.code === 401) {
        userAuth.clearAuth()
        window.location.href = `/${language}`
      } else if (error && error.is_deleted) {
        dispatch({
          buttonLoading: false,
          isDeleted: true,
          openPopup: true,
          render: true
        })
      }
    }
  }

  function SendButton() {
    return (
      <div
        onClick={submitComment}
        className={classNames({
          'flex justify-center items-center w-12 h-12 rounded-full pl-1': true,
          'cursor-pointer bg-primary': !disabledButton,
          'cursor-not-allowed bg-gray': disabledButton
        })}
      >
        {buttonLoading ? <div className="lds-dual-ring"></div> : <SendIcon />}
      </div>
    )
  }

  function renderCommentTextarea() {
    return (
      <div className="relative">
        <div className="absolute -top-5">
          <div className="comment-label">
            <span className="text-lg text-white mr-2.5 -mb-0.5 uppercase">
              {t('add_comment.comments')}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16.37 16.34"
              width="16.37"
              height="16.34"
            >
              <g data-name="Layer 2">
                <path
                  d="M15.86 8.17a7.9 7.9 0 01-1.11 3.91.34.34 0 000 .31c.36.95.7 1.89 1 2.84a.42.42 0 010 .47.41.41 0 01-.46.1l-3.45-.8a.45.45 0 00-.28 0 7.65 7.65 0 114.14-8.22c.08.46.11.93.16 1.39z"
                  fill="#ffffff"
                  stroke="#ffffff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.02}
                  data-name="Layer 1"
                />
              </g>
            </svg>
          </div>
        </div>

        <textarea
          name="comment"
          id="comment_input"
          rows="3"
          className="w-full align-middle bg-lightGray outline-none focus:outline-none pl-10 pb-4 pt-8 pr-16 font-sans text-lg"
          placeholder={t('add_comment.add_a_comment')}
          value={comment}
          onChange={handleChangeText}
        ></textarea>
        <div className="absolute bottom-5 right-5">
          <SendButton />
        </div>
      </div>
    )
  }

  function handleChangeText(e) {
    const { value } = e.target
    const text = value.replace(/^\s+|\s+$/g, '')
    dispatch({
      comment: value,
      disabledButton: !text,
      render: true
    })
  }

  const {
    media,
    status,
    like_count,
    is_liked,
    is_mine,
    comment_count,
    comments,
    content
  } = video || {}

  const hasMedia = !!media && !!media.url
  const metadata = hasMedia ? media.metadata : {}

  function renderMedia() {
    if (!hasMedia) return <div></div>

    return (
      <video
        src={(media || {}).url}
        controls
        controlsList="nodownload"
        playsInline
        className={classNames({
          'align-middle outline-none focus:outline-none': true,
          'h-full': metadata && metadata.width <= metadata.height,
          'w-full': metadata && metadata.width > metadata.height
        })}
        onContextMenu={(e) => e.preventDefault()}
      />
    )
  }

  function handleDelete(event) {
    if (typeof onDeleteVideo === 'function') {
      onDeleteVideo(uuid)
    }
  }

  function handleEdit(event) {
    if (typeof onEditVideo === 'function') {
      onEditVideo(uuid, language)
    }
  }

  return (
    <>
      {!loading && (
        <div
          className={classNames({
            'm-auto': true,
            'max-w-5xl': hasMedia,
            'max-w-3xl': !hasMedia
          })}
        >
          <div className="flex flex-col sm:flex-row w-full sm:rounded-3xl sm:overflow-hidden mt-14 sm:shadow-post sm:h-135 relative">
            {hasMedia && (
              <div className="w-full sm:w-1/2 flex justify-center items-center bg-black">
                {renderMedia()}
              </div>
            )}

            <div
              className={classNames({
                'w-full relative': true,
                'sm:w-1/2': hasMedia
              })}
            >
              <div className="flex flex-col h-full">
                <div
                  className={classNames({
                    'pb-2  px-5 sm:px-10': true,
                    'pt-10': is_mine,
                    'pt-6': !is_mine
                  })}
                >
                  <LikeCommentBox
                    uuid={uuid}
                    likeCount={like_count}
                    commentCount={comment_count}
                    isLiked={is_liked}
                    size="xl"
                  />
                </div>
                <div className="sm:overflow-y-scroll h-full sm:max-h-full  px-5 sm:px-10 mb-5 scrollbar-styled">
                  <p className="text-base">
                    <ShowMore
                      lines={3}
                      more={t('add_comment.show_more')}
                      less={t('add_comment.show_less')}
                      anchorClass="text-primary"
                    >
                      {content}
                    </ShowMore>
                  </p>
                  <CommentListing comments={comments} />
                </div>
                {isAuthenticated && renderCommentTextarea()}
              </div>
            </div>
            {is_mine && (
              <div
                style={{ maxWidth: 282 }}
                className={classNames({
                  'absolute w-full p-2 right-0 top-0': true
                })}
              >
                <StatusBadge
                  status={status}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                  shadow={true}
                />
              </div>
            )}
          </div>
          {isAuthenticated ? (
            <div className="pt-8 pb-8 px-5 sm:px-0">
              <p className="text-sm font-bold text-primary mb-3">
                {t('terms_and_conditions.title')}
              </p>
              <p className="text-sm font-light text-gray">
                {t('terms_and_conditions.term_1')}
              </p>
              <p className="text-sm font-light text-gray">
                {t('terms_and_conditions.term_2')}
              </p>
              <p className="text-sm font-light text-gray">
                {t('terms_and_conditions.term_3')}
              </p>
            </div>
          ) : (
            <div className="pt-8 pb-8 px-5 sm:px-0">
              <p className="text-sm font-light text-gray">
                {parse(t('main_page.footer_note'))}
              </p>
            </div>
          )}
        </div>
      )}
      <Popup
        open={openPopup}
        handleClosePopup={() =>
          dispatch({
            openPopup: false,
            render: true
          })
        }
      >
        <div className="p-3 flex flex-col items-center justify-between">
          <div>
            <p className="text-gray sm:text-lg text-center">
              {isDeleted
                ? t('my_video.post_has_been_deleted')
                : t('my_video.your_comment_is_under_review')}
            </p>
          </div>
          <div className="flex mt-5">
            <Button
              title={t('my_video.ok')}
              className={'h-9 min-w-25 mr-4'}
              classNameText={'font-bold'}
              onClick={() => {
                if (isDeleted) {
                  window.location.href = `/${language}`
                } else {
                  dispatch({
                    openPopup: false,
                    render: true
                  })
                }
              }}
            />
          </div>
        </div>
      </Popup>
      <Loading loading={loading} />
    </>
  )
}

export default VideoView
