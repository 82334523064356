import React from 'react'
import Select from 'react-select'
import { classNames, classNameObject } from '../../utils/index'
import './style.css'

function formatOptionLabel({ label }) {
  return (
    <span className="flex item-center border-none text-sm">
      <p>{label}</p>
    </span>
  )
}
const customStyles = {
  control: () => ({
    border: '2px solid rgb(204, 204, 204)',
    borderRadius: '24px',
    height: 40,
    minHeight: 40,
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    paddingLeft: 20,
    paddingRight: 20
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#68737A',
    cursor: 'pointer'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(104, 115, 122, 0.5)'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#68737A'
  })
}

function SelectMenu({
  options,
  value,
  onChange,
  className,
  placeholder,
  classNamePrefix
}) {
  return (
    <Select
      value={value}
      formatOptionLabel={formatOptionLabel}
      styles={customStyles}
      options={options}
      className={classNames({
        'cursor-pointer': true,
        ...classNameObject(className)
      })}
      classNamePrefix={classNamePrefix}
      isSearchable={false}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

export default SelectMenu
