import React, { useContext } from 'react'
import Button from '../Button'
import PlusIcon from '../Icons/PlusIcon'
import MyVideoIcon from '../Icons/MyVideoIcon'
import BackButton from '../Button/Back'
import LoginButton from '../Button/Login'
import userAuth from '../../utils/userAuth'
import parse from 'html-react-parser'
import { UserContext } from 'contexts/UserProvider'

function HeaderButtonsGroup({
  showBackButton,
  onClickBackButton,
  t,
  onClickMyVideo,
  onClickAddVideo,
  onClickLogin,
  isMyVideoActive,
  showAnnouncement,
  isClosed
}) {
  const { me } = useContext(UserContext)
  const { isAuthenticated } = userAuth.getAuth()
  return (
    <div className="flex flex-col pt-8 sm:pt-0 justify-center items-center relative">
      {showBackButton && (
        <BackButton
          className="absolute -top-4 -left-1 sm:left-0"
          onClick={onClickBackButton}
        />
      )}
      <h1 className="sm:text-10 text-2xl leading-tight text-center font-bold text-primary uppercase">
        {t('main_page.so_you_think_you_can_dance')}
      </h1>
      {isClosed && (
        <div className="mt-8">
          <p className="text-center text-base sm:text-2xl text-gray">
            {parse(t('main_page.submission_closed'))}
          </p>
        </div>
      )}
      {isAuthenticated ? (
        !(me || {}).isManager && (
          <div className="flex flex-col sm:flex-row justify-center items-center mt-4">
            {!isClosed && (
              <Button
                className="min-w-58 sm:mr-8 h-11 "
                classNameText="font-extrabold text-lg uppercase"
                title={t('after_login.add_a_video')}
                Icon={PlusIcon}
                onClick={onClickAddVideo}
              />
            )}
            {!isClosed && (
              <Button
                className="min-w-58 mt-4 sm:mt-0 h-11 "
                classNameText="font-extrabold text-lg uppercase"
                active={isMyVideoActive}
                title={t('after_login.my_videos')}
                Icon={MyVideoIcon}
                onClick={onClickMyVideo}
              />
            )}
          </div>
        )
      ) : (
        <>
          {showAnnouncement && !isClosed && (
            <div className="mt-10 text-gray text-lg sm:text-2xl text-center">
              <div className="leading-tight">
                {parse(t('main_page.announcement'))}
              </div>
            </div>
          )}
          <LoginButton
            className="mt-10"
            title={t('main_page.login')}
            note={t('main_page.login_button_note')}
            onClick={onClickLogin}
          />
        </>
      )}
    </div>
  )
}

export default HeaderButtonsGroup
