import React, { useState, useReducer, useEffect } from 'react'
import { classNames } from '../../utils/index'
import axios from '../../utils/axios'
import userAuth from '../../utils/userAuth'
import { useTranslation } from 'react-i18next'
function LikeCommentBox({
  likeCount,
  commentCount,
  isLiked,
  uuid,
  size = 'base'
}) {
  const [currentState, setCurrentState] = useState(0)
  const { isAuthenticated } = userAuth.getAuth()
  const { i18n } = useTranslation()
  const { language } = i18n
  function reducer(state, action) {
    const updatedValues = state
    if ('likeCount' in action) {
      updatedValues.likeCount = action.likeCount
    }
    if ('isLiked' in action) {
      updatedValues.isLiked = action.isLiked
    }

    if ('render' in action && !!action.render) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }

  const [values, dispatch] = useReducer(reducer, {
    likeCount: likeCount,
    isLiked: isLiked,
    render: false
  })

  useEffect(() => {
    dispatch({
      likeCount: likeCount,
      isLiked: isLiked,
      render: true
    })
  }, [isLiked, likeCount])

  async function handleLike(event) {
    if (!isAuthenticated) return null

    event.preventDefault()
    event.stopPropagation()
    try {
      const resp = await axios.post(`/videos/${uuid}/toggle_like`)
      const { success, like_count, is_liked } = resp
      if (success) {
        dispatch({
          likeCount: like_count,
          isLiked: is_liked,
          render: true
        })
      }
    } catch (error) {
      if (error && error.code === 401) {
        userAuth.clearAuth()
        window.location.href = `/${language}`
      }
    }
  }

  return (
    <div className="inline-flex justify-between items-center">
      <svg
        onClick={handleLike}
        xmlns="http://www.w3.org/2000/svg"
        width="17.79"
        height="16.34"
        viewBox="0 0 17.79 16.34"
        className="cursor-pointer"
      >
        <g data-name="Layer 2">
          <path
            d="M8.89 15.87L8 15c-1-.88-2-1.77-3-2.65-.79-.69-1.59-1.37-2.32-2.13a7.91 7.91 0 01-1.49-1.93 6.12 6.12 0 01-.61-1.9 9.57 9.57 0 01-.11-1.77A4.21 4.21 0 013.32.73 4.41 4.41 0 018 1.85a5.15 5.15 0 01.8 1l.05.08.15-.08A5 5 0 0111 1a4.29 4.29 0 012.33-.51 4.25 4.25 0 013.94 3.66 7.43 7.43 0 010 1.7 6.29 6.29 0 01-.34 1.66 6.45 6.45 0 01-1.29 2.17 20.56 20.56 0 01-2 2l-3.12 2.73c-.53.47-1 1-1.57 1.45z"
            fill={values.isLiked ? '#eb1e2f' : 'none'}
            stroke="#eb1e2f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.93}
            data-name="Layer 1"
          />
        </g>
      </svg>

      <p
        className={classNames({
          'mr-4 ml-2 -mb-1 ie:mb-0 text-primary': true,
          'text-base': size === 'base',
          'text-xl': size === 'xl'
        })}
      >
        {values.likeCount}
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16.37 16.34"
        width="16.37"
        height="16.34"
      >
        <g data-name="Layer 2">
          <path
            d="M15.86 8.17a7.9 7.9 0 01-1.11 3.91.34.34 0 000 .31c.36.95.7 1.89 1 2.84a.42.42 0 010 .47.41.41 0 01-.46.1l-3.45-.8a.45.45 0 00-.28 0 7.65 7.65 0 114.14-8.22c.08.46.11.93.16 1.39z"
            fill={commentCount > 0 ? '#eb1e2f' : 'none'}
            stroke="#eb1e2f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.02}
            data-name="Layer 1"
          />
        </g>
      </svg>
      <p
        className={classNames({
          'ml-2 -mb-1 ie:mb-0 text-primary': true,
          'text-base': size === 'base',
          'text-xl': size === 'xl'
        })}
      >
        {commentCount}
      </p>
    </div>
  )
}

export default LikeCommentBox
