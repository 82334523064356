import React, { useCallback, useContext } from 'react'
import Video from '../Video'
import Masonry from '../Masonry'
import LoadMoreButton from '../Button/LoadMore'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import noVideoIcon from '../../images/icons/no_post_icon_video.png'
import { UserContext } from 'contexts/UserProvider'
import { useTranslation } from 'react-i18next'
dayjs.extend(relativeTime)

function VideoListing({
  onLoadMore,
  data,
  canLoadMore,
  onSelectVideo,
  onEditVideo,
  onDeleteVideo,
  t,
  loading,
  isClosed
}) {
  const { i18n } = useTranslation()
  const handleSelectVideo = useCallback((uuid) => {
    onSelectVideo(uuid)
  }, [])
  const handleEditVideo = useCallback((uuid) => {
    if (!isClosed) {
      onEditVideo(uuid, i18n.language)
    }
  }, [])
  const handleDeleteVideo = useCallback((uuid) => {
    if (!isClosed) {
      onDeleteVideo(uuid)
    }
  }, [])
  const { me } = useContext(UserContext)

  return (
    <div className="p-10 m-auto">
      {data && data.length < 1 && !loading && !isClosed && (
        <div className="p-6 flex flex-col justify-center items-center">
          <img width={250} src={noVideoIcon} />
          <p className="text-2xl sm:text-3xl font-bold text-primary mt-5">
            {t('main_page.no_videos_yet')}
          </p>
          {!(me || {}).isManager && (
            <p className="text-base sm:text-2xl font-light text-gray text-center">
              {' '}
              {t('main_page.post_a_video')}
            </p>
          )}
        </div>
      )}
      <Masonry brakePoints={[600, 850]}>
        {data.map((itemData, index) => (
          <Video
            key={itemData.uuid}
            onClick={handleSelectVideo}
            onEdit={handleEditVideo}
            onDelete={handleDeleteVideo}
            data={itemData}
            dayjs={dayjs}
            index={index}
          />
        ))}
      </Masonry>
      <div className="flex justify-center mt-8">
        {!loading && canLoadMore && data.length > 0 && (
          <LoadMoreButton onClick={onLoadMore} />
        )}
      </div>
    </div>
  )
}
export default VideoListing
