import React, { useState, useReducer, useEffect, useContext } from 'react'
import { classNames, formatErrors } from 'utils'
import { isBlankString, isEmptyErrors } from 'utils/validate'
import MediaUploader from '../../components/MediaUploader'
import TextArea from '../../components/TextArea'
import Agreement from './Agreement'
import BackButton from 'components/Button/Back'
import axios from 'utils/axios'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import userAuth from 'utils/userAuth'
import browser from 'browser-detect'
import useDimensions from 'react-cool-dimensions'
import { ResizeObserver } from '@juggle/resize-observer'
import TextInput from '../../components/TextInput/index'
import Select from '../../components/Select/index'
import { UserContext } from 'contexts/UserProvider'

const STATUS_UPLOAD_VIDEO = Object.freeze({
  PREPARE: 0,
  READY: 10,
  UPLOADING: 20,
  SUCCESS: 30
})

export default function AddYourVideoPage({ match }) {
  const currentBrowser = browser()
  const { observe } = useDimensions({
    onResize: ({ observe, width, height }) => {
      if (currentBrowser.name === 'ie') {
        resizeIEForm({
          width: width,
          height: height
        })
      }
    },
    polyfill: ResizeObserver
  })

  function resizeIEForm({ width, height }) {
    try {
      document.getElementById('main_form').style.height = `${height + 52}px`
    } catch (e) {
      console.log('Error Resize: ', e)
    }
  }
  const { me } = useContext(UserContext)
  const { uuid } = match.params
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [currentState, setCurrentState] = useState(0)
  function reducer(state, action) {
    const updatedValues = state
    if ('id' in action) {
      updatedValues.id = action.id
    }
    if ('media' in action) {
      updatedValues.media = action.media
    }
    if ('defaultMedia' in action) {
      updatedValues.defaultMedia = action.defaultMedia
    }
    if ('content' in action) {
      updatedValues.content = action.content
      if (isBlankString(updatedValues.content)) {
        updatedValues.errors.content = t('add_your_story.caption_error_blank')
      } else {
        updatedValues.errors.content = ''
      }
    }
    if ('teamName' in action) {
      updatedValues.teamName = action.teamName
      updatedValues.errors.teamName = isBlankString(action.teamName)
    }
    if ('numberOfTeamMember' in action) {
      updatedValues.numberOfTeamMember = action.numberOfTeamMember
      let newTeamMembers = updatedValues.teamMembers
      if (action.numberOfTeamMember > 0) {
        if (action.numberOfTeamMember > updatedValues.teamMembers.length) {
          let newFields = []
          const length =
            action.numberOfTeamMember - updatedValues.teamMembers.length

          for (let index = 0; index < length; index++) {
            newFields.push({
              firstName: '',
              lastName: '',
              firstNameError: true,
              lastNameError: true
            })
          }
          newTeamMembers = [...updatedValues.teamMembers, ...newFields]
        } else if (
          action.numberOfTeamMember < updatedValues.teamMembers.length
        ) {
          let newTeamMembers = [...updatedValues.teamMembers]
          for (
            let index = 0;
            index < updatedValues.teamMembers.length;
            index++
          ) {
            if (index >= action.numberOfTeamMember) {
              newTeamMembers[index]._remove = true
            }
          }
        }
        if (!uuid) {
          newTeamMembers[0].firstName = me.first_name
          newTeamMembers[0].lastName = me.last_name
          newTeamMembers[0].firstNameError = false
          newTeamMembers[0].lastNameError = false
        }

        updatedValues.teamMembers = newTeamMembers.filter(
          (teamMember) => teamMember._remove !== true
        )
      }
    }

    if ('teamMember' in action) {
      const { index, firstName, lastName } = action.teamMember
      let newTeamMembers = [...updatedValues.teamMembers]
      if (newTeamMembers[index]) {
        newTeamMembers[index].firstName = firstName
        newTeamMembers[index].lastName = lastName
        newTeamMembers[index].firstNameError = isBlankString(firstName)
        newTeamMembers[index].lastNameError = isBlankString(lastName)
      }
      updatedValues.teamMembers = newTeamMembers
    }
    if ('teamMembers' in action) {
      updatedValues.teamMembers = action.teamMembers
    }
    if ('agreement' in action) {
      updatedValues.agreement = action.agreement
      updatedValues.errors.agreement = !action.agreement
    }
    if ('status' in action) {
      updatedValues.status = action.status
    }
    if ('loading' in action) {
      updatedValues.loading = action.loading
    }
    if ('errors' in action) {
      updatedValues.errors = { ...updatedValues.errors, ...action.errors }
    }
    if (action.render) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }

  const [values, dispatch] = useReducer(reducer, {
    id: null,
    media: '',
    defaultMedia: null,
    content: null,
    agreement: false,
    status: STATUS_UPLOAD_VIDEO['PREPARE'],
    numberOfTeamMember: null,
    teamMembers: [],
    teamName: null,
    errors: {}
  })

  const {
    content,
    defaultMedia,
    media,
    status,
    errors,
    agreement,
    numberOfTeamMember,
    teamName
  } = values

  useEffect(() => {
    let isMounted = true
    if (uuid) {
      fetchData(isMounted)
    }
    return () => {
      isMounted = false
    }
  }, [])

  async function fetchData(isMounted) {
    try {
      const resp = await axios.get(`/videos/${uuid}/edit`)
      const { video } = resp
      dispatch({
        defaultMedia: video.media
          ? {
              url: video.media.url,
              type: 'video'
            }
          : '',
        content: video.content,
        teamName: (video.team || {}).name,
        teamMembers: (video.team || {}).members.map((teamMember) => ({
          firstName: teamMember.first_name,
          lastName: teamMember.last_name,
          firstNameError: false,
          lastNameError: false
        })),
        numberOfTeamMember: (video.team || {}).members.length,
        loading: false,
        render: isMounted
      })
    } catch (error) {
      if (error && error.code === 403) {
        window.location.href = `/${language}`
      }
    }
  }

  function handleMediaChange({ file }) {
    dispatch({ media: file, render: true })
  }

  function handleToggleAgreement(status) {
    dispatch({
      content: values.content,
      agreement: !agreement,
      status: STATUS_UPLOAD_VIDEO[status],
      render: true
    })
  }

  async function submitResult() {
    if (status === STATUS_UPLOAD_VIDEO['UPLOADING'] || !canSubmit()) {
      return null
    }
    if (!agreement) {
      dispatch({ render: true, errors: { agreement: true } })
      return null
    }
    if (status === STATUS_UPLOAD_VIDEO['READY']) {
      const formData = new FormData()
      if (values.media && typeof values.media === 'object') {
        formData.append('video[media]', values.media)
      }

      formData.append(
        'video[content]',
        isBlankString(values.content) ? '' : values.content
      )
      formData.append('video[locale]', language === 'vi' ? 'vn' : language)
      formData.append('team[name]', values.teamName)
      values.teamMembers.forEach((teamMember, index) => {
        formData.append(
          `team[team_members_attributes][${index}][first_name]`,
          teamMember.firstName
        )
        formData.append(
          `team[team_members_attributes][${index}][last_name]`,
          teamMember.lastName
        )
      })

      dispatch({
        status: STATUS_UPLOAD_VIDEO['UPLOADING'],
        render: true
      })
      try {
        let resp
        if (uuid) {
          resp = await axios.patch(`/videos/${uuid}`, formData)
        } else {
          resp = await axios.post('/videos', formData)
        }
        const { success } = resp
        if (success) {
          history.push(`/${language}/acknowledgement`)
        }
      } catch (error) {
        if (error && error.code === 401) {
          userAuth.clearAuth()
          window.location.href = `/${language}`
        } else {
          const { errors } = error
          dispatch({
            status: STATUS_UPLOAD_VIDEO['READY'],
            errors: formatErrors(errors),
            render: true
          })
        }
      }
    } else {
      alert('Need to Agree firstly!!!!')
    }
  }
  function handleClickBack() {
    history.push(`/${language}`)
  }

  function renderTeamMembers() {
    return values.teamMembers.map((teamMember, index) => {
      return (
        <div
          key={index}
          className="flex sm:flex-row flex-col sm:items-center mt-6 mb-6"
        >
          <div className="sm:mr-6">
            <p className="font-bold text-gray" style={{ whiteSpace: 'nowrap' }}>
              {t('add_your_story.team_member')} {index + 1}
            </p>
          </div>
          <TextInput
            placeholder={t('add_your_story.first_name')}
            defaultValue={teamMember.firstName}
            className={'sm:mr-6 mt-3 sm:mt-0'}
            // error={teamMember.firstNameError}
            onChange={(value) =>
              dispatch({
                teamMember: {
                  index: index,
                  firstName: value,
                  lastName: teamMember.lastName
                },
                render: true
              })
            }
          />
          <TextInput
            placeholder={t('add_your_story.last_name')}
            defaultValue={teamMember.lastName}
            className={'mt-4 sm:mt-0'}
            // error={teamMember.lastNameError}
            onChange={(value) =>
              dispatch({
                teamMember: {
                  index: index,
                  firstName: teamMember.firstName,
                  lastName: value
                },
                render: true
              })
            }
          />
        </div>
      )
    })
  }

  function canSubmit() {
    let canSubmit = true

    if (isBlankString(teamName) || !numberOfTeamMember) {
      canSubmit = false
    }

    if (media === null || (media === '' && !defaultMedia)) {
      canSubmit = false
    }

    values.teamMembers.forEach((teamMember) => {
      if (teamMember.lastNameError || teamMember.firstNameError) {
        canSubmit = false
      }
    })

    return canSubmit
  }

  return (
    <div className="px-4 lg:px-0 max-w-101 w-full mx-auto">
      <div className="flex flex-col items-center w-full sm:py-16 pb-16 pt-10 relative ">
        <div className="title relative w-full flex sm:items-center sm:justify-center sm:flex-row flex-col items-start ">
          <BackButton
            className="block sm:absolute sm:left-0 -top-3 sm:mb-0 mb-5"
            onClick={() => handleClickBack()}
          />
          <div className="text-primary text-3xl sm:text-10 font-bold text-center uppercase leading-none w-full">
            {uuid
              ? t('add_your_story.edit_your_story')
              : t('add_your_story.add_your_story')}
          </div>
        </div>
        <div
          className="main-form w-full form-container mt-10 px-4 sm:px-14 pt-3 pb-10 shadow-content-wrapper rounded-3xl"
          id="main_form"
        >
          <div className="form-wrapper" ref={observe} id="form_wrapper">
            <div className="upload-buttons-container">
              <MediaUploader
                mode={'video'}
                defaultMedia={defaultMedia}
                onMediaSelect={(file) => handleMediaChange(file)}
              />
            </div>
            <div className="text-area-container mt-8 mb-7">
              <TextArea
                defaultValue={content || ''}
                placeholder={t('add_your_story.placeholder_caption')}
                onChange={(text) =>
                  dispatch({
                    content: text,
                    render: true
                  })
                }
                // error={errors['content']}
              />
            </div>
            <div className="flex sm:flex-row flex-col">
              <TextInput
                placeholder={t('add_your_story.team_name')}
                defaultValue={teamName || ''}
                className={'sm:mr-6'}
                onChange={(value) =>
                  dispatch({
                    teamName: value,
                    render: true
                  })
                }
              />
              <Select
                value={
                  numberOfTeamMember
                    ? { label: numberOfTeamMember, value: numberOfTeamMember }
                    : null
                }
                className="w-full mt-4 sm:mt-0"
                classNamePrefix={'no-of-team-members'}
                placeholder={t('add_your_story.no_of_team_members')}
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                  { label: 6, value: 6 },
                  { label: 7, value: 7 }
                ]}
                onChange={({ value }) => {
                  dispatch({
                    numberOfTeamMember: value,
                    render: true
                  })
                }}
              />
            </div>
            <div>{renderTeamMembers()}</div>
            <div className="agreement-container mb-5 mt-10">
              <Agreement
                error={!!errors && errors.agreement}
                errorText={t('add_your_story.agree_before_posting')}
                onToggleAgreement={(status) => handleToggleAgreement(status)}
              />
            </div>
            <div className="submit-button-container flex justify-center">
              <button
                type="button"
                className={classNames({
                  'text-xl rounded-full text-white  border font-extrabold focus:ring-0 outline-none focus:outline-none py-1.5 px-1.5 transition-all duration-500 ease-in-out min-h-post-button min-w-post-button': true,
                  'bg-primary border-primary': canSubmit(),
                  'bg-button-bg border-button-bg pointer-events-none':
                    !canSubmit() || status === STATUS_UPLOAD_VIDEO['UPLOADING']
                })}
                onClick={submitResult}
              >
                {status === STATUS_UPLOAD_VIDEO['UPLOADING'] ? (
                  <div className="mt-1 text-center">
                    {t('add_your_story.post')}...
                  </div>
                ) : (
                  <div className="mt-1 text-center">
                    {t('add_your_story.post')}
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
