import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Header from '../components/Header'
import Footer from '../components/Footer'
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import 'dayjs/locale/id'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'
import VideoBanner from 'components/VideoBanner'

const LANGUAGES = ['en', 'vi', 'zh', 'cn', 'id']
const DAYJS_LOCALES = {
  en: 'en',
  vi: 'vi',
  id: 'id',
  cn: 'zh-cn',
  zh: 'zh-tw'
}

function DefaultLayout({
  component: Component,
  t,
  i18n,
  path,
  hasVideoBanner = false,
  ...props
}) {
  const locale = props.computedMatch.params.locale || 'en'
  console.log('path :>> ', path)
  const dayjsLocale = DAYJS_LOCALES[locale]
  dayjs.locale(dayjsLocale)
  useEffect(() => {
    if (LANGUAGES.includes(locale)) {
      i18n.changeLanguage(locale)
    } else {
      window.location.href = '/'
    }
  }, [])

  return (
    <Route
      {...props}
      render={(matchProps) => (
        <div className="flex flex-col justify-between min-h-screen mx-auto">
          <Header locale={locale} />
          {hasVideoBanner && <VideoBanner />}
          <div className="w-full flex justify-center min-h-min-body-content">
            <div className="max-w-screen-xl w-full">
              <Component {...matchProps} t={t} i18n={i18n} />
            </div>
          </div>
          <Footer locale={locale} />
        </div>
      )}
    />
  )
}

export default withTranslation()(DefaultLayout)
