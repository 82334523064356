import React from 'react'
import getConfig from 'utils/getConfig'

function VideoBanner() {
  const videoUrl = getConfig().videoBannerUrl

  return (
    <header className="opacity-80 relative flex items-center justify-center overflow-hidden">
      <div className="w-auto min-w-full min-h-full">
        <video
          src={videoUrl}
          controls
          controlsList="nodownload"
          autoPlay
          loop
          muted
          className={'w-full'}
          playsInline
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    </header>
  )
}

export default React.memo(VideoBanner)
