import React, { useState, useReducer, useEffect } from 'react'
import VideoListing from '../components/VideoListing'
import HeaderButtonsGroup from '../components/HeaderButtonsGroup'
import VideoDetail from '../containers/VideoDetail'
import axios from '../utils/axios'
import { useHistory } from 'react-router-dom'
import Loading from '../components/Loading/index'
import userAuth from '../utils/userAuth'
import Popup from '../components/Popup/index'
import Button from '../components/Button/index'
import { useTranslation } from 'react-i18next'

const ALL_POSTS = 1
const MY_VIDEOS = 2
const POST_DETAIL = 3

function Home({ t }) {
  const history = useHistory()
  const [currentState, setCurrentState] = useState(0)
  const { i18n } = useTranslation()
  const { language } = i18n
  const isClosed = false

  function reducer(state, action) {
    const updatedValues = state
    if ('section' in action) {
      updatedValues.section = action.section
    }
    if ('videos' in action) {
      updatedValues.videos = action.videos
    }
    if ('selectedVideoUuid' in action) {
      updatedValues.selectedVideoUuid = action.selectedVideoUuid
    }
    if ('selectedDeleteUuid' in action) {
      updatedValues.selectedDeleteUuid = action.selectedDeleteUuid
    }
    if ('page' in action) {
      updatedValues.page = action.page
    }
    if ('total' in action) {
      updatedValues.total = action.total
    }
    if ('openDeletePopup' in action) {
      updatedValues.openDeletePopup = action.openDeletePopup
    }
    if ('loading' in action) {
      updatedValues.loading = action.loading
    }
    if ('render' in action && !!action.render) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }

  const [values, dispatch] = useReducer(reducer, {
    section: ALL_POSTS,
    videos: [],
    selectedVideoUuid: null,
    selectedDeleteUuid: null,
    openDeletePopup: false,
    page: 1,
    total: 0,
    loading: true,
    render: false
  })

  const {
    section,
    videos,
    selectedVideoUuid,
    selectedDeleteUuid,
    page,
    total,
    loading,
    openDeletePopup
  } = values

  useEffect(() => {
    let isMounted = true
    if (section === MY_VIDEOS) {
      fetchMyVideos(isMounted)
    } else if (section === ALL_POSTS) {
      fetchAllVideos(isMounted)
    }
    return () => {
      isMounted = false
    }
  }, [section])

  async function fetchAllVideos(isMounted) {
    dispatch({
      loading: true,
      render: true
    })
    try {
      const resp = await axios.get('/videos', {
        params: {
          per_page: 10,
          page: page
        }
      })
      const { records, next_page, total } = resp
      dispatch({
        videos: page === 1 ? records : [...videos, ...records],
        page: next_page,
        loading: false,
        total: total,
        render: isMounted
      })
    } catch (error) {
      if (error && error.code === 401) {
        userAuth.clearAuth()
        window.location.reload()
      }
    }
  }

  async function fetchMyVideos(isMounted) {
    dispatch({
      loading: true,
      render: true
    })
    try {
      const resp = await axios.get('/videos/my_videos', {
        params: {
          per_page: 10,
          page: page
        }
      })
      const { records, next_page, total } = resp
      dispatch({
        videos: page === 1 ? records : [...videos, ...records],
        page: next_page,
        loading: false,
        total,
        render: isMounted
      })
    } catch (error) {
      if (error && error.code === 401) {
        userAuth.clearAuth()
        window.location.reload()
      }
    }
  }

  function onLoadMore() {
    if (!!page && !loading) {
      dispatch({ loading: true, render: true })
      if (section === MY_VIDEOS) {
        fetchMyVideos(true)
      } else if (section === ALL_POSTS) {
        fetchAllVideos(true)
      }
    }
  }
  function handleClickAddVideo() {
    history.push(`/${language}/add-your-video`)
  }

  function handleClickLogin() {
    history.push(`/${language}/login`)
  }

  function handleSelectVideo(uuid) {
    dispatch({ selectedVideoUuid: uuid, section: POST_DETAIL, render: true })
  }

  function handleEditVideo(uuid, language) {
    history.push(`/${language}/add-your-video/${uuid}`)
  }

  function handleDeleteVideo(uuid) {
    dispatch({ openDeletePopup: true, selectedDeleteUuid: uuid, render: true })
  }
  async function deleteVideo() {
    try {
      const resp = await axios.delete(`/videos/${selectedDeleteUuid}`)
      const { success } = resp
      if (success) {
        const newVideos = videos.filter(
          (video) => video.uuid !== selectedDeleteUuid
        )
        dispatch({
          loading: false,
          videos: newVideos,
          section: MY_VIDEOS,
          page: 1,
          total: total - 1,
          selectedVideoUuid: null,
          openDeletePopup: false,
          render: true
        })
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }

  return (
    <>
      <div className="h-full pb-10">
        <div className="px-5 sm:p-10 ">
          <HeaderButtonsGroup
            t={t}
            showAnnouncement={section === ALL_POSTS}
            showBackButton={section !== ALL_POSTS}
            onClickBackButton={() =>
              dispatch({
                section: ALL_POSTS,
                page: 1,
                selectedVideoUuid: null,
                render: true
              })
            }
            onClickMyVideo={() =>
              dispatch({
                section: MY_VIDEOS,
                page: 1,
                selectedVideoUuid: null,
                render: true
              })
            }
            isClosed={isClosed}
            onClickAddVideo={handleClickAddVideo}
            onClickLogin={handleClickLogin}
            isMyVideoActive={section === MY_VIDEOS}
          />
        </div>

        <div className="px-0 sm:px-10">
          {selectedVideoUuid ? (
            <VideoDetail
              uuid={selectedVideoUuid}
              t={t}
              onEditVideo={handleEditVideo}
              onDeleteVideo={handleDeleteVideo}
            />
          ) : (
            <VideoListing
              data={videos}
              isClosed={isClosed}
              onLoadMore={onLoadMore}
              onSelectVideo={handleSelectVideo}
              onEditVideo={handleEditVideo}
              onDeleteVideo={handleDeleteVideo}
              canLoadMore={!!page && videos.length < total}
              loading={loading}
              t={t}
            />
          )}
        </div>
        <Popup
          open={openDeletePopup}
          handleClosePopup={() =>
            dispatch({
              openDeletePopup: false,
              selectedDeleteUuid: null,
              render: true
            })
          }
        >
          <div className="p-2 flex flex-col items-center justify-between">
            <div>
              <p className="text-gray sm:text-lg text-center">
                {t('my_video.delete_video_confirmation')}
              </p>
            </div>
            <div className="flex mt-5">
              <Button
                title={t('login.close')}
                className={'h-9 min-w-25 mr-4'}
                classNameText={'font-bold'}
                onClick={() =>
                  dispatch({
                    openDeletePopup: false,
                    selectedDeleteUuid: null,
                    render: true
                  })
                }
              />
              <Button
                className={'h-9 min-w-25'}
                classNameText={'font-bold'}
                title={t('my_video.ok')}
                onClick={deleteVideo}
              />
            </div>
          </div>
        </Popup>
        <Loading loading={loading} />
      </div>
    </>
  )
}

export default Home
