import React, { useState, useEffect } from 'react'
import axios from 'utils/axios'

const UserContext = React.createContext()

function UserProvider({ children }) {
  const [me, setMe] = useState(null)
  const [loadingUser, setLoadingUser] = useState(false)

  async function fetchMyProfile() {
    if (loadingUser) return
    setLoadingUser(true)
    try {
      const resp = await axios.get('/my_profile')
      setLoadingUser(false)
      resp.isManager = resp.delegate_type && resp.delegate_type.code === 'staff'
      setMe(resp)
    } catch (error) {
      setLoadingUser(false)
      setMe(null)
    }
  }

  useEffect(() => {
    fetchMyProfile()
  }, [])

  return (
    <UserContext.Provider
      value={{
        me,
        loadingUser,
        fetchMyProfile
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const UserConsumer = UserContext.Consumer

export default UserProvider
export { UserConsumer, UserContext }
