import React, { useState, useEffect } from 'react'
import { classNames, classNameObject } from 'utils'

export default function TextArea({
  name: inputName,
  className,
  placeholder,
  onChange,
  error,
  defaultValue
}) {
  const [state, setState] = useState({
    text: defaultValue,
    error: error
  })

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      error: error
    }))
  }, [error])

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      text: defaultValue
    }))
  }, [defaultValue])

  function handleChange(event) {
    const value = event.target.value
    setState((prev) => ({
      ...prev,
      text: value
    }))
  }
  function handleBlur(event) {
    const value = event.target.value
    onChange(value)
  }

  return (
    <textarea
      name={inputName}
      onChange={handleChange}
      onBlur={handleBlur}
      value={state.text}
      className={classNames({
        ...classNameObject(className),
        'w-full border-2 focus:ring-0  rounded-3xl h-80 focus:outline-none outline-none sm:py-7 py-5 sm:px-11 px-3 text-gray placeholder-gray-opacity-50 resize-none text-sm sm:text-lg': true,
        'focus:border-gray-border  border-gray-border': !error,
        'focus:border-primary  border-primary': error
      })}
      placeholder={placeholder}
    />
  )
}
