import React from 'react'
import { useTranslation } from 'react-i18next'
import backToTop from '../../images/backtotop.svg'
import parse from 'html-react-parser'
import { stripHtml } from 'string-strip-html'

function Footer({ locale }) {
  const { t } = useTranslation()

  function handleClickToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  function stripBrHtml(string) {
    return stripHtml(string, {
      onlyStripTags: ['br']
    }).result
  }

  const homeRoute = locale ? `/${locale}` : '/'
  const locales = {
    en: 'en',
    cn: 'sc',
    zh: 'tc',
    vi: 'vn',
    id: 'id'
  }

  const pruEventLocale = locales[locale]

  const onDemandLink =
    process.env.REACT_APP_HOST_TYPE === 'production'
      ? ` https://www.ondemand.pruevent-asia.com/${locale == 'zh' ? 'cn' : locale}`
      : `https://ondemand-prumdrt2021.projectwebby.com/${locale == 'zh' ? 'cn' : locale}`
  return (
    <div className="w-full flex justify-center bg-button-bg">
      <div className=" w-full max-w-screen-xl">
        <div className="flex justify-center py-4 text-md sm:text-lg text-white  relative mx-auto">
          <span
            className="absolute right-4 sm:right-8 -top-14 sm:-top-20 w-10 h-10 sm:w-14 sm:h-14 cursor-pointer"
            onClick={() => handleClickToTop()}
          >
            <img src={backToTop}></img>
          </span>
          <div className="flex flex-col w-full sm:w-footer-rate mx-4">
            <div className="flex my-5 flex-col sm:flex-row">
              <div className="flex flex-col flex-shrink-0 w-full sm:w-7/12 lg:w-5/12 text-lg sm:text-xl pb-6 sm:pb-0 text-center sm:text-left">
                <span className="mb-6">
                  {t('privacy_notice_footer.contact_us')}
                </span>
                <span>
                  {parse(t('privacy_notice_footer.for_any_event_queries'))}
                </span>
              </div>
              <div className="flex justify-between w-full">
                <div className="flex flex-col font-bold sm:mx-auto">
                  <a
                    className="cursor-pointer sm:px-2 pb-1"
                    href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}`}
                    rel="noreferrer"
                  >
                    {parse(stripBrHtml(t('privacy_notice_footer.home')))}
                  </a>
                  <a
                    className="cursor-pointer sm:px-2 pb-1"
                    href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}/agenda`}
                    rel="noreferrer"
                  >
                    {parse(stripBrHtml(t('privacy_notice_footer.agenda')))}
                  </a>
                  <a
                    className="cursor-pointer sm:px-2 pb-1"
                    href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}/speakers`}
                    rel="noreferrer"
                  >
                    {parse(t('privacy_notice_footer.speakers'))}
                  </a>
                  <a
                    className="cursor-pointer sm:px-2 pb-1"
                    href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}/wedo`}
                    rel="noreferrer"
                  >
                    {parse(
                      stripBrHtml(t('privacy_notice_footer.we_do_activities'))
                    )}
                  </a>
                </div>
                <div className="flex flex-col font-bold">
                  <a
                    className="cursor-pointer sm:px-2 pb-1"
                    href={`https://prumdrt2021.pruevent-asia.com/${pruEventLocale}/runtomdrt`}
                    rel="noreferrer"
                  >
                    {parse(stripBrHtml(t('privacy_notice_footer.run_to_mdrt')))}
                  </a>
                  <a className="cursor-pointer sm:px-2 pb-1" href={homeRoute}>
                    {parse(
                      stripBrHtml(t('main_page.so_you_think_you_can_dance'))
                    )}
                  </a>
                  <a className="cursor-pointer sm:px-2 pb-1" href={onDemandLink}>
                    {parse(
                      stripBrHtml(t('main_page.on_demand'))
                    )}
                  </a>
                  <a
                    className="cursor-pointer sm:px-2 pb-1"
                    href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}/faq`}
                    rel="noreferrer"
                  >
                    {parse(stripBrHtml(t('privacy_notice_footer.faq')))}
                  </a>
                </div>
              </div>
            </div>
            <hr className="opacity-30" />
            <div className="mt-4 text-center sm:text-left">
              <span className="text-lg sm:text-xl font-bold">
                {t('privacy_notice_footer.privacy_policy')}
              </span>
              <p className="text-sm sm:text-md mt-5">
                {t('privacy_notice_footer.content')}
              </p>
              <p className="text-sm sm:text-md mt-5 mb-8">
                {t('privacy_notice_footer.copyright')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
