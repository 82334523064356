import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import Home from './pages/home'
import Login from './pages/login'
import DefaultLayout from './layouts/DefaultLayout'
import AuthLayout from './layouts/AuthLayout'
import AddYourVideoPage from './pages/AddYourVideoPage'
import SubmissionAcknowledgement from './pages/SubmissionAcknowledgement'
import CommentAcknowledgement from './pages/commentAcknowledgement'
import './App.css'
import UserProvider from 'contexts/UserProvider'

function App() {
  return (
    <UserProvider>
      <Router>
        <Switch>
          <DefaultLayout exact path="/" component={Home} hasVideoBanner />
          <DefaultLayout exact path="/login" component={Login}></DefaultLayout>
          <AuthLayout
            exact
            path="/add-your-video"
            component={AddYourVideoPage}
          />
          <AuthLayout
            exact
            path="/add-your-video/:uuid"
            component={AddYourVideoPage}
          />
          <AuthLayout
            exact
            path="/acknowledgement"
            component={SubmissionAcknowledgement}
          />
          <AuthLayout
            exact
            path="/comment-acknowledgement"
            component={CommentAcknowledgement}
          />
          <DefaultLayout
            exact
            path="/:locale"
            component={Home}
            hasVideoBanner
          ></DefaultLayout>
          <DefaultLayout
            exact
            path="/:locale/login"
            component={Login}
          ></DefaultLayout>
          <AuthLayout
            exact
            path="/:locale/add-your-video"
            component={AddYourVideoPage}
          />
          <AuthLayout
            exact
            path="/:locale/add-your-video/:uuid"
            component={AddYourVideoPage}
          />
          <AuthLayout
            exact
            path="/:locale/acknowledgement"
            component={SubmissionAcknowledgement}
          />
          <AuthLayout
            exact
            path="/:locale/comment-acknowledgement"
            component={CommentAcknowledgement}
          />
        </Switch>
      </Router>
    </UserProvider>
  )
}

export default App
