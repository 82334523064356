import React, { useState, useEffect } from 'react'
import { classNames, classNameObject } from 'utils'

function TextInput({
  name: inputName,
  className,
  placeholder,
  onChange,
  error,
  defaultValue
}) {
  const [state, setState] = useState({
    text: defaultValue || '',
    error: error
  })

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      error: error
    }))
  }, [error])

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      text: defaultValue
    }))
  }, [defaultValue])

  function handleChange(event) {
    const value = event.target.value
    setState((prev) => ({
      ...prev,
      text: value
    }))
  }

  function handleBlur(event) {
    const value = event.target.value
    onChange(value)
  }
  return (
    <input
      className={classNames({
        ...classNameObject(className),
        'w-full border-2 focus:ring-0  rounded-3xl h-10 focus:outline-none outline-none py-4 sm:px-5 px-2.5 text-gray placeholder-gray-opacity-50 resize-none text-sm sm:text-lg': true,
        'focus:border-gray-border  border-gray-border': !state.error,
        'focus:border-primary  border-primary': state.error
      })}
      name={inputName}
      onChange={handleChange}
      onBlur={handleBlur}
      value={state.text}
      placeholder={placeholder}
    />
  )
}

export default React.memo(TextInput)
