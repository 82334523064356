import React from 'react'

export default function MyVideoIcon({ active }) {
  const strokeColor = active ? '#ffffff' : '#68737a'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.6 25.6"
      width="25.6"
      height="25.6"
    >
      <defs>
        <style>
          {`.post_prefix__cls-1{fill:none;stroke: ${strokeColor} ;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`}
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <rect
            className="post_prefix__cls-1"
            x={0.75}
            y={0.75}
            width={24.1}
            height={24.1}
            rx={1.5}
          />
          <path
            className="post_prefix__cls-1"
            d="M3.54 3.59h18.51v16.42H3.54z"
          />
        </g>
      </g>
    </svg>
  )
}
